<template>
  <div class="user-wrap">
    <UserBackground :userId="userId"/>

    <!-- 用户卡片 -->
    <UserCard 
      :type="'1'"
      :userInfo="userInfo" 
      :personLabels="personLabels"
      :like="like"
      :disLike="disLike"
      @handleReviewClick="handleReview" 
      @handleSocialClick="handleShowSocialPop" 
    />

    <!-- 用户基本信息 -->
    <UserBaseInfo :userInfo="userInfo" :photosShow="true" />

    <!-- 社交账号 -->
    <SocialPop v-model="socialPopShow" :userId="userId" :telegram="userInfo.telegram" :wechat="userInfo.wechat" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserBackground from '../../components/user/UserBackground.vue'
import UserCard from '../../components/user/UserCard.vue'
import UserBaseInfo from '../../views/user/UserBaseInfo.vue'
import SocialPop from '../../views/user/SocialPop.vue'
import { queryUserInfoById, reportSocialAccount } from '@/service/user'
import { getLikeAndDislike } from '../../service/feedback'
import $gbUtils from "@/utils/gbUtils"

export default {
  name: '',
  components: { UserBackground, UserCard, UserBaseInfo, SocialPop },
  data() {
    return {
      socialPopShow: false,
      userId: null,
      userInfo: {},
      personLabels: [],
      bgImg: '',
      like: false,
      disLike: false
    }
  },
  computed: {
    $gbUtils() {
      return $gbUtils
    },
    isSign() {
      return this.$gbUtils.isSign();
    }
  },
  methods: {
    ...mapActions([
      "changeShowLoginPop",
    ]),
    async init() {
      await this.getUserInfo()
      await this.queryLikeOrDisLike()
    },
    handleShowSocialPop() {
      if (!this.isSign) {
        this.changeShowLoginPop(true)
        return
      }
      if (
        this.$gbUtils.isEmptyString(this.userInfo.telegram)
        && this.$gbUtils.isEmptyString(this.userInfo.wechat)
      ) {
        this.$toast(this.$t('userDetail.noSocialTip'))
        return
      }
      this.socialPopShow = !this.socialPopShow
      // 上报查看社交账号
      try {
        const params = {
          targetId: this.userId,
          action: 10
        }
        reportSocialAccount(params)
      } catch(e) {
      }
    },
    //跳转到评论页面
    handleReview() {
      this.$router.push({
        path: '/EditReview',
        query: {
          userId: this.userId
        }
      })  
    },
    async getUserInfo() {
      if (this.userId) {
        const params = {
          userId: this.userId
        }
        const resp = await queryUserInfoById(params)
        if (resp.success) {
          this.formatUserInfo(resp.data)
        } else {
          console.log('userDetail page ==> getUserInfo Error, errMsg=', resp)
        }
      }
    },
    // 处理用户详情信息
    formatUserInfo (data) {
      this.userInfo = data
      if (this.userInfo.age) this.personLabels.push(this.userInfo.age + '岁')
      if (this.userInfo.job) this.personLabels.push(this.userInfo.job)
      if (this.userInfo.location) this.personLabels.push(this.userInfo.location)
      // 拼接图片域名前缀
      if (this.userInfo.photos) {
        const imagesArr  = this.userInfo.photos.split(',')
        this.userInfo.photoList = imagesArr.map(item => { return this.$gbUtils.formatImgUrl(item)})
      }
      if (this.userInfo.often) {
        this.userInfo.oftenList = this.userInfo.often.split(',')
      }
      this.userInfo.locationName = this.$gbUtils.getAreaNameByCode(this.userInfo.location)
      const bgImgStr = this.userInfo.backgroundPhoto ? this.userInfo.backgroundPhoto : this.userInfo.headPhoto
      this.bgImg = this.$gbUtils.formatImgUrl(bgImgStr)
      this.$forceUpdate()
    },
    async queryLikeOrDisLike() {
      const params = {
        resourceId: this.userId
      }
      const resp = await getLikeAndDislike(params)
      if (!resp) return
      this.like = resp.data.like
      this.disLike = resp.data.dislike
    }
  },
  mounted() {
    document.documentElement.scrollTop = document.body.scrollTop = 0
    this.userId = this.$route.query.userId
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.user-wrap {
  position: relative;
  height: 100%;
  .tabs {
    width: 100%;
    display: flex;
    padding: 10px;

    .tab {
      display: flex;
      margin-right: 20px;
      height: 22px;
      align-items: center;
    }
  }

  .profit {
    padding: 0 10px;
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-bottom: 0;
  }
}
</style>
