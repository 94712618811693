<template>
  <van-popup
    :value="value"
    :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)' }"
    :style="{ width: '100%', maxHeight: '80%', background: '#1E232D', padding: '0 10px' }"  
    :transition-appear=true
    position="bottom" 
    duration="0.5"
    @input="val => this.$emit('input', val)"
    @close='closePop'
    round
  >
    <div class="socail-wrap">
      <div class="top-white-line"></div>
      <div class="title">{{ $t('userDetail.socialAccount') }}</div>
      <div class="content">
        <div class="item tg" v-if="$gbUtils.notEmptyString(telegram)">
          <div><img src="@/assets/img/icon/tg.svg"  alt=""/> Telegram  </div>
          <div>{{ formatTelegram }}</div>
        </div>
        <div class="item wechat" v-if="$gbUtils.notEmptyString(wechat)">
          <div><img src="@/assets/img/icon/wechat.svg" /> {{ $t('userDetail.wechat') }} </div>
          <div> {{ wechat }} </div>
        </div>
      </div>
      <div class="opt" :class="{'center': onlyOneContact}">
        <VanButton class="btn1" v-if="$gbUtils.notEmptyString(telegram)" @click="handleCopyTg">{{ $t('userDetail.copyTg') }}</VanButton>
        <VanButton class="btn2" v-if="$gbUtils.notEmptyString(wechat)" @click="handleCopyWechat">{{ $t('userDetail.copyWechat') }}</VanButton>
      </div>
      <div class="botton-white-line"></div>
    </div>

  </van-popup>

</template>

<script>
import { reportSocialAccount } from '@/service/user'
import $gbUtils from "@/utils/gbUtils";
export default {
  components: { },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    telegram: {
      type: String,
      default: ''
    },
    wechat: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    $gbUtils() {
      return $gbUtils
    },
    onlyOneContact () {
      return (
        (this.$gbUtils.isEmptyString(this.telegram) && this.$gbUtils.notEmptyString(this.wechat)) 
        || (this.$gbUtils.notEmptyString(this.telegram) && this.$gbUtils.isEmptyString(this.wechat))
      )
    },
    formatTelegram () {
      if (this.telegram.startsWith('@')) {
        return this.telegram
      } else {
        return `@${this.telegram}`
      }
    }
  },
  methods: {
    closePop () {
      this.$emit('input', false)
    },
    handleCopyTg(){
      this.$gbUtils.handleCopy(this.formatTelegram)
      try {
        reportSocialAccount({ targetId: this.userId, action: 20, remark: 'TG' })
      }catch(e) {}
     
    },
    handleCopyWechat() {
      this.$gbUtils.handleCopy(this.wechat)
      try {
        reportSocialAccount({ targetId: this.userId, action: 30, remark: 'Wechat' })
      }catch(e) {}
    }
  }
}
</script>

<style lang="scss" scoped>
.pop-wrap {
  padding: 0 !important;
  border-radius: 6px;
}

.socail-wrap {
  color: #fff;
  padding: 20px 10px;
  .title {
    font-size: 20px;
    font-weight: 600;
    padding-top: 30px;
  }
  
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    .item {
      width: 100%;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      background: #282A3B;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 20px;
      font-size: 13px;
      >div {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
    }
    :last-child {
      margin-bottom: 0;
    }
    .tg {
      background: #34334e;
    }
    .wechat {
      background: #4b223e;
    }
    
  }

  .opt {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 60px;
    .btn1, .btn2 {
      width: 48%;
      height: 38px;
      color: #fff;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      /deep/.van-button__text {
        font-size: 13px;
        font-weight: 600;
      }
    }
    .btn1 {
      background: #8A73D0;
    }
    .btn2 {
      background: #FD2084;
    }
  }
  .center{
    display: flex;
    justify-content: center;
  }
}
.van-popup--bottom.van-popup--round {
  border-radius: 10px 10px 0 0;
}
</style>